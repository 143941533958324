
import { useEffect, useState } from "react";
import useScanDetection from 'use-scan-detection';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { getOnePalette } from "../../Services/Palettes/Palettes";
import { getOnePrep } from "../../Services/Commandes/Commandes";
import { CircularProgress } from "@mui/material";
import ChannelPresenter from "../Channels/ChannelPresenter";
import ButtonActionStep from "../Button/ButtonActionStep";

function getToDisplayable(step){
    if(step === "prepare"){
        return "Préparation";
    }else if(step === "control"){
        return "Contrôler";
    }else if(step === "control-responsible"){
        return "Contrôler par un Responsable";
    }else if(step === "pack"){
        return "Emballer";
    }else if(step === "charge"){
        return "Charger";
    }else if(step === "expect"){
        return "Expédié";
    }
}

const UserScan = (props) =>{
    let ean = props.ean;

    return (<h1>Product : {ean}</h1>)
}

const ProductScan = (props) =>{
    let ean = props.ean;

    return (<h1>Product : {ean}</h1>)
}
const PaletteScan = (props) =>{
    let ean = props.ean;
    let id_palette = ean.slice(3, 13);
    const [data, setData] = useState(null);


    useEffect(() => {
        getOnePalette("/palettes/"+id_palette).then((result)=>{
            getOnePrep(result["orderPreparation"]['@id'].replace("/order_preparations/", "")).then((result2)=>{
                setData([result2, result]);
            });
        });
    }, [ean, id_palette])

    if(data){
        return (
            <>
                <b>PALETTE : {data[1]['typeName'] + " #" + data[1]['number']}</b><br/><br/>
                Client : {data[0].customer.lastname} {data[0].customer.firstname} <br></br>
                Numéro : {data[0].customer.phoneNumber} <br></br>
                Société : {data[0].customer.company} <br></br>
                
                <br></br>
                    <ChannelPresenter channel={data[0].channel} number={data[0].orderId}/>
                <br></br>
                <br></br>

                Étapes actuel : {getToDisplayable(data[0].currentStep)}<br></br>

                <ButtonActionStep step={data[0].step} target={data[0]['@id']}/>
                <table style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>Produit(s)</th>
                            <th>Quantité</th>
                            <th>Palette(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{data[0].totalProducts}</td>
                            <td>{data[0].totalProductsQuantity}</td>
                            <td>{data[0].totalPalettes}</td>
                        </tr>
                    </tbody>
                </table>         

            </>

        );
    }else{
        return <CircularProgress/>
    }
    
}

const NavetteScan = (props) =>{
    let ean = props.ean;

    return (<h1>Navette : {ean}</h1>)
}

const CommandeScan = (props) =>{
    let ean = props.ean;

    return (<h1>Commande : {ean}</h1>)
}

const WarehouseLocationScan = (props) =>{
    let ean = props.ean;

    return (<h1>WarehouseLocation : {ean}</h1>)
}

const ScanContext = (props) => {
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const [component, setComponent] = useState(null);

    useScanDetection({
        onComplete: (code) => { setValue(code.toString().replaceAll("Shift", "")) },
        minLength: 13 // EAN13
    });


    useEffect(() => {
        if(value !== ""){
            handleClickOpen();
            setComponent(null)
            if(value.length === 12 || value.length === 13){
                const prefix = value.slice(0, 3);
                switch(prefix){
                    case "200": setComponent(<UserScan ean={value}/>)
                                break;
                                
                    case "210":
                    case "201":
                    case "202":
                    case "370": handleClose();
                                break;
                    case "203": setComponent(<PaletteScan ean={value}/>)
                                break;
                    case "204": setComponent(<NavetteScan ean={value}/>)
                                break;
                    case "205": setComponent(<CommandeScan ean={value}/>)
                                break;
                    default: setComponent(<span style={{textAlign:"center", width:"100%", display: "block"}}>Aucun match</span>)
                            break;
                }
            }
        }
    },[value])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setValue("");
    };    

    return (
        <>
        <input style={{display: "none"}} 
            value={value} 
            type="text"
            readOnly
        />
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
            <DialogContentText>

                {  component  }

            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

export default ScanContext;