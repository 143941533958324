import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { getOneEmplacement } from "../../Services/Emplacements/Emplacements";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//import { VIEW_PIN } from "../../global-config";
import NumpadPin from "./NumpadPin";
import { getIdWithEmail } from "../../Services/Auth/Authentification";

const LockedSpotButton = (props) =>{
    let spot = props.spot;
    let locked = props.locked;
    let setLocked = props.setLocked;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pin, setPin] = useState("000");
    const [open, setOpen] = useState(false);

    const [viewPin, setViewPin] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        setLocked(true);
        if(spot !== null){
            getIdWithEmail().then((user)=>{
                setViewPin(user['hydra:member'][0].viewPin);
                getOneEmplacement(spot).then((data) => {
                    setData(data);
                    setPin(data.pin);
                    setLoading(false);
                });
            });
        }else{
            setLoading(false);
        }
    }, [spot]);
    if (loading) return <CircularProgress size="1rem"/>;
    const styles = {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px", 
        fontWeight: "700", 
        WebkitBoxPack: "center", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100px",
        height: "100px", 
        minWidth: "100px", 
        minHeight: "100px", 
        marginTop: "8px",
        cursor: "pointer",
        marginLeft: "auto",
        marginRight: "auto",
    }


    function clickHandleLock(){
        if(locked){
            handleClickOpen();
        }else{
            setLocked(true);
        }
    }

    return(<>

        <div onClick={clickHandleLock} style={locked ? {...styles, color: "rgb(153, 0, 0)",border: "2px solid rgb(153, 0, 0)", backgroundColor: "rgb(255, 204, 204)"} : {...styles, color: "rgb(0, 151, 0)",border: "2px solid rgb(43, 175, 43)", backgroundColor: "rgb(204, 255, 204)"}}>
            {locked? <LockIcon/> : <LockOpenIcon/>}
            {data? data.code : "-"} 

        </div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Déverrouiller l'emplacement</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Pour accéder et confirmer l'emplacement, merci de spécifier les 3 derniers chiffres du code PIN à l'emplacement où vous vous trouvez : 
                {viewPin? <><br></br>Code pin : {pin}</>:<></>}
            </DialogContentText>
            <NumpadPin handleClose={handleClose} toggleSnack={props.toggleSnack} setLocked={setLocked} pincode={pin}/>
            </DialogContent>
        </Dialog>
    </>)
}
export default LockedSpotButton;
