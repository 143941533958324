import { Connect, getTokenLoginScan, getTokenLoginScanAndTryLogin } from "../../Services/Auth/Authentification";
import './Login.css';
import { Paper, TextField, Button, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import AppBar from "../AppBar/AppBar";
import useScanDetection from 'use-scan-detection';
import { ENTRY_POINT } from "../../global-config";
import { useNavigate } from "react-router-dom";

const LoginScan = (props) => {
    const [value, setValue] = useState("");
    const navigate = useNavigate();
    
    let toggleSnack = props.toggleSnack;
    useScanDetection({
        onComplete: (code) => { setValue(code.toString().replaceAll("Shift", "")) },
        minLength: 128 // EAN13
    });


    useEffect(() => {
        if(value !== ""){
            getTokenLoginScanAndTryLogin(value, toggleSnack).then((data)=>{
                setTimeout(function(){

                    navigate("/");

                }, 1000);
            });
        }
    },[value])

    return (
        <>
        <input style={{display: "none"}} 
            value={value} 
            type="text"
            readOnly
        />
        </>
    );
};

const LoginPage = ({ toggleSnack }) =>{
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [btnStyleText, setbtnStyleText] = useState("SE CONNECTER");
    const [btnStyleColor, setbtnStyleColor] = useState("success");
    const navigate = useNavigate();
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            con();
        }
    };

    function con(){
        setbtnStyleText("En cours de connexion");
        setbtnStyleColor("warning");
        Connect(email, pass).then(response => {
            if(response === false){
                setbtnStyleText("SE CONNECTER");
                setbtnStyleColor("success");
                toggleSnack(true, "Erreur lors de la connexion", "error");
            }else{
                setbtnStyleText("CONNEXION REUSSI");
                setbtnStyleColor("success");
                toggleSnack(true, "Connexion établie avec succès", "success");
                setTimeout(function(){

                    navigate("/");

                }, 1000);
            }
        });
    }

    function handleEmail(e){
        var target = e.target;
        setEmail(target.value);
    }

    function handlePass(e){
        var target = e.target;
        setPass(target.value);
    }

    return(
        <div id="loginPage">
            <AppBar/>
            <Paper className="loginPanel" elevation={6}>
                <TextField variant="standard"
                    helperText="Entrer votre adresse email"
                    onChange={handleEmail}
                    onKeyDown={handleKeyDown}
                    type="email"
                    margin="dense"
                    label="Email"
                /><br></br>
                <TextField variant="standard"
                    type="password"
                    margin="dense"
                    onChange={handlePass}
                    onKeyDown={handleKeyDown}
                    label="Mot de passe"
                /><br></br>
                <Button onClick={con} variant="contained" color={btnStyleColor}>
                    {btnStyleText}
                </Button>
            </Paper>
            <LoginScan toggleSnack={toggleSnack}/>
        </div>
    )
}
export default LoginPage;