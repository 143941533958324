import { Button, ButtonGroup, CircularProgress, Fab, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import MediaObjectPresenter from "../../component/MediaObject/MediaObjectPresenter";
import { getOnePrep, getOnePrepItem, getPrepItems } from "../../Services/Commandes/Commandes";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AttributesToArrayDisplayer from "../../component/Product/AttributeToArrayDisplayer";
import ProductCondiAndWeightDisplayer from "../../component/Product/ProductCondiAndWeightDisplayer";
import LockedSpotButton from "../../component/Button/LockedSpotButton";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CompassToSpot from "../../component/ProposalContent/CompassToSpot";
import DeleteButtonWithConfirm from "../../component/Palettes/ButtonDeletePalette";
import ButtonPalettePrint from "../../component/Palettes/ButtonPrint";
import AddPalette from "../../component/Palettes/AddPalette";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { addItemToPaletteWithQuantity, getOnePalette } from "../../Services/Palettes/Palettes";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { stepPrepFinish } from "../../Services/Preparation/Preparation";
import ButtonEditPalette from "../../component/Palettes/ButtonEditPalette";
import { getOneTransporteurs } from "../../Services/Transporteurs/Transporteurs";
import { getOneProduct } from "../../Services/Catalogue/Catalogue";
import useScanDetection from 'use-scan-detection';

const ButtonRemoveWithPackage = (props) => {
    let id_product = props.id_product;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        getOneProduct(id_product).then((data)=>{
            setData(data);
            setLoading(false);
        });
    }, [id_product]);


    if (loading) return <></>;
    if(data.packagingType === "" || data.packagingQuantity === "" || data.packagingUnit === "") return <></>;
    return (<Button disabled={props.locked?true:false} onClick={props.actionPlusOrMinus} packagingQuantity={data.packagingQuantity} actionbtn={'minuspackage'}><RemoveIcon/>P</Button>)

}

const ButtonAddWithPackage = (props) => {
    let id_product = props.id_product;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoading(true);
        getOneProduct(id_product).then((data)=>{
            setData(data);
            setLoading(false);
        });
    }, [id_product]);


    if (loading) return <></>;
    if(data.packagingType === "" || data.packagingQuantity === "" || data.packagingUnit === "") return <></>;
    return (<Button disabled={props.locked?true:false} onClick={props.actionPlusOrMinus} packagingQuantity={data.packagingQuantity} actionbtn={'pluspackage'}><AddIcon/>P</Button>)

    
}

const DisplayAndAttribQuantityToPalette = (props) =>{
    let selectedPalette = props.selectedPalette;
    let data_items = props.data_items;
    let selectedPrepItems = props.selectedPrepItems;
    let number = 0;
    
    if(selectedPrepItems.paletteArray.length >0){
        selectedPrepItems.paletteArray.forEach((Palette)=>{
            if(Palette.id === selectedPalette){
                number = Palette.quantity;
            }
        });
    }
    /** Calcule le nombre total de produits sur la palette */
    /* if(data_items.length > 0){
        data_items.forEach((items)=>{
            items.paletteItems.forEach((paletteItem) => {
                
                if(paletteItem.palette === selectedPalette){
                    number = paletteItem.quantity;
                }
            });
        })
    } */
    return number;
}

const PreparationSystem = (props) =>{
    let data_prep = props.data;
    let id_prep = data_prep['@id'];
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [selectedPrepItems, setSelectedPrepItems] = useState(null);
    const [locked, setLocked] = useState(true);
    const [quantityToAdd, setQuantityToAdd] = useState(0);
    const [selectedPalette, setSelectedPalette] = useState(null);
    const [selectedPaletteWeight, setSelectedPaletteWeight] = useState(null);
    const [transportMaxWeight, settransportMaxWeight] = useState(null);
    const [reload, setReload] = useState(false);
    const [reloadweight, setReloadWeight] = useState(false);


    const [palettesPrep, setPalettesPrep] = useState(props.palettes_prep);
    const [reloadPalette, setReloadPalette] = useState(false);
    const [loadingPaletteList, setLoadingPaletteList] = useState(true);

    const [numberOfSelectedPrepItem, setnumberOfSelectedPrepItem] = useState(0); 
    
    const [codeScan, setCodeScan] = useState("");
    const navigate = useNavigate();


    function handleReload(){
        setReload(!reload);
    }

    function handleReloadWeight(){
        setReloadWeight(!reloadweight);
    }

    function handleReloadPalette(){
        setReloadPalette(!reloadPalette);
    }

    function selectPaletteAfterAdd(id_palette){
        setSelectedPalette(id_palette);
    }

    function handleReloadPrepItemSelected(){
        getPrepItems(id_prep).then((data) => {
            setData(data['hydra:member']);
            setSelectedPrepItems(data['hydra:member'][numberOfSelectedPrepItem]);
            setQuantityToAdd(0);
            props.ghostReload();
        })
    }

    useEffect(() => {
        getPrepItems(id_prep).then((data) => {
            setData(data['hydra:member']);
            setSelectedPrepItems(data['hydra:member'][numberOfSelectedPrepItem]);
            setQuantityToAdd(0);
            props.ghostReload();
        })
    }, [numberOfSelectedPrepItem]);

    const styles = {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px", 
        fontWeight: "700", 
        WebkitBoxPack: "center", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100px",
        height: "100px", 
        minWidth: "100px", 
        minHeight: "100px", 
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
    }

    function handleQuantityToAdd(e){
        let value = e.target.value;
        setQuantityToAdd(value);
    }

    function handleChangePalette(e){
        let value = e.target.value;
        setSelectedPalette(value);
    }

    function actionPlusOrMinus(e){
        let action = e.target.getAttribute("actionbtn");
        if(action === null){
            action = e.target.closest('button').getAttribute("actionbtn");
        }
        switch(action){
            case "plus":    
                        if((quantityToAdd + 1) > (selectedPrepItems.quantity - selectedPrepItems.quantityTotalPrepared)){
                            setQuantityToAdd(selectedPrepItems.quantity - selectedPrepItems.quantityTotalPrepared);
                        }else{
                            setQuantityToAdd(quantityToAdd + 1);
                        }
                        break;
            case "doubleplus":
                        setQuantityToAdd(selectedPrepItems.quantity - selectedPrepItems.quantityTotalPrepared)
                        break;
            case "minus":
                        if((quantityToAdd-1) < (Math.abs(selectedPrepItems.quantityTotalPrepared) * -1)){
                           setQuantityToAdd(Math.abs(selectedPrepItems.quantityTotalPrepared) * -1);
                        }else{
                           setQuantityToAdd(quantityToAdd -1);
                        }
                        break;
            case "doubleminus":
                        setQuantityToAdd(Math.abs(selectedPrepItems.quantityTotalPrepared) * -1)
                        break;

            case "minuspackage":
                        var val = e.target.closest('button').getAttribute("packagingQuantity");
                        setQuantityToAdd(quantityToAdd - (Math.abs(val) * 1));
                        
                        break;
            case "pluspackage":
                        var val = e.target.closest('button').getAttribute("packagingQuantity");
                        setQuantityToAdd(quantityToAdd + Math.abs(val));
                        break;
            default:
                        break;
        }
        
    }

    function addToPalette(){
        if(quantityToAdd === 0){
            return props.toggleSnack(true, "0 n'est pas un chiffre valide", "error");
        }
        if((quantityToAdd >= (Math.abs(selectedPrepItems.quantityTotalPrepared) * -1)) && ((quantityToAdd) <= (selectedPrepItems.quantity - selectedPrepItems.quantityTotalPrepared))){
            
            addItemToPaletteWithQuantity(selectedPrepItems['@id'], selectedPalette, quantityToAdd).then((result)=>{
                
                
                if(selectedPrepItems.quantity - selectedPrepItems.quantityTotalPrepared === quantityToAdd){
                    
                    let numberMaxofPrepItems = data_prep.items.length;
                    let test = parseInt(numberOfSelectedPrepItem) + 1;
                    if(test < numberMaxofPrepItems){
                        setnumberOfSelectedPrepItem(test);
                        setQuantityToAdd(0);
                        props.toggleSnack(true, "Ajout avec succès.", "success");
                    }else{
                        handleReloadPrepItemSelected();
                        setQuantityToAdd(0);
                        props.toggleSnack(true, "Ajout avec succès.", "success");
                    }
                }else{
                    handleReloadPrepItemSelected();
                    setQuantityToAdd(0);
                    props.toggleSnack(true, "Ajout avec succès.", "success");
                }
                handleReloadWeight();
            });
        }else{
            props.toggleSnack(true, "Vous ne pouvez pas ajouter plus que la quantité requise ou retirer plus que la quantité déja préparée.", "warning");
        }
    }

    useEffect(() => {
        setLoading(true);
        getPrepItems(id_prep).then((data) => {
            setData(data['hydra:member']);
            setSelectedPrepItems(data['hydra:member'][0]);
            if(typeof data['hydra:member'][0].paletteArray[0].id === "undefined" || data['hydra:member'][0].paletteArray[0].id === null){
                setSelectedPalette(data_prep.palettes[0]['@id']);
            }else{
                setSelectedPalette(data['hydra:member'][0].paletteArray[0].id);
            }
            
            setQuantityToAdd(0);
            setLoading(false);
        })
        
    }, [id_prep, reload]);


    useEffect(() => {
        setLoadingPaletteList(true);
        getOnePrep(id_prep.replace('/order_preparations/', "")).then((data) => {
            setPalettesPrep(data.palettes);
            setLoadingPaletteList(false);
        });
        
    }, [id_prep, reloadPalette]);

    useEffect(()=>{
        
        getOneTransporteurs(data_prep['carrier']['@id']).then((data)=>{
            let maxWeight = data['maxWeightPerPalette'];
            settransportMaxWeight(maxWeight);
        });
        if(selectedPalette!==null){
            getOnePalette(selectedPalette).then((data_palette) =>{
                let weightActual = data_palette['weight'];
                setSelectedPaletteWeight(weightActual);
            });
        }

    }, [selectedPalette, reloadPalette, reloadweight, data_prep]);

    function selectThisPrepItems(e){
        let numberPrepItem = e.target.closest(".squareSwitch").getAttribute('numberPrepItem');
        setnumberOfSelectedPrepItem(numberPrepItem);
        setLoading(true);
        setSelectedPrepItems(data[numberPrepItem]);
        setLoading(false);
        setQuantityToAdd(0);
    }

    function finishPrep(){
        stepPrepFinish(id_prep).then((result)=>{
            props.ghostReload();
            navigate('/controler/'+id_prep.replace('/order_preparations/', ''));
        });
    }

    useScanDetection({
        preventDefault: true,
        onComplete: (code) => { setCodeScan(code.toString().replaceAll("Shift", "")) },
        minLength: 13 // EAN13
    });

    useEffect(() => {
        if(codeScan !== ""){
            console.log(selectedPrepItems);
            console.log(selectedPrepItems.spot.ean);
            if(codeScan === selectedPrepItems.spot.ean || selectedPrepItems.product['@id'].includes(codeScan)){
                setLocked(false);
                setCodeScan("");
                props.toggleSnack(true, "Déverouillage de l'emplacement effectué", 'success');
            }
            console.log(codeScan);
        }
    },[codeScan, selectedPrepItems]);

    if (loading) return <LinearProgress/>
    return(
        <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={4} style={{textAlign: "center", overflow: "hidden"}}>
                    {selectedPrepItems.product?<MediaObjectPresenter height="96" width="96" mediaobjectid={selectedPrepItems.product.image?selectedPrepItems.product.image['@id']:""} /> :<></>}
                </Grid>
                <Grid item xs={6} md={4} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <span style={{width: "100%", fontWeight: "bold", textAlign: "center"}}>{selectedPrepItems.product?selectedPrepItems.product.name:""}</span>
                    <span style={{width: "auto", marginLeft: "auto", marginRight: "auto", textAlign: "center", display: "flex"}}><AttributesToArrayDisplayer attributes={selectedPrepItems.product.attributesToArray} withCustom={selectedPrepItems.custom}/></span>
                </Grid>
                <Grid item xs={6} md={3} style={{overflow: "auto"}}>
                    <ProductCondiAndWeightDisplayer id_product={selectedPrepItems.product['@id']} />
                </Grid>
            </Grid>
        </Box>
        <hr></hr>
        
        <Box sx={{ flexGrow: 1 }}>
            <Grid container style={{textAlign: "center"}} spacing={2}>
                <Grid item xs={12} md={3}>
                            <p><b>Emplacement</b></p>
                            <LockedSpotButton toggleSnack={props.toggleSnack} locked={locked} setLocked={setLocked} spot={selectedPrepItems.spot.id} />
                            
                            <input style={{display: "none"}} 
                                value={codeScan} 
                                type="text"
                                readOnly
                            />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container style={{textAlign: "center"}} spacing={2}>
                            <Grid item xs={4} md={4}>
                                <p><b>Besoin</b></p>
                                <div style={{...styles, backgroundColor: "#ecf0f1", border: "1px solid #bdc3c7", color: "black"}}>
                                    <span>{selectedPrepItems.quantity}</span>
                                    <span style={{textTransform: "uppercase"}}>{selectedPrepItems.quantityNeeded}</span>
                                </div>
                                <ButtonGroup
                                style={{marginTop: "31px", height: "50px", marginLeft: "auto", marginRight: "auto", width: "100%", justifyContent: "center"}}
                                disableElevation
                                variant="contained"
                                >
                                    <Button disabled={locked?true:false} onClick={actionPlusOrMinus} actionbtn={'doubleminus'}><RemoveIcon/><RemoveIcon/></Button>
                                    <ButtonRemoveWithPackage locked={locked} actionPlusOrMinus={actionPlusOrMinus} id_product={selectedPrepItems.product['@id']}/>
                                    <Button disabled={locked?true:false} onClick={actionPlusOrMinus} actionbtn={'minus'}><RemoveIcon/></Button>
                                </ButtonGroup>
                                
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <p><b>A préparer</b></p>
                                <div style={{...styles, backgroundColor: "#ecf0f1", border: "1px solid #bdc3c7", color: "black"}}>
                                    <span>{selectedPrepItems.quantityTotalToPrepare}</span>
                                    <span style={{textTransform: "uppercase"}}>{selectedPrepItems.quantityToPrepare}</span>
                                </div>
                                <ArrowDownwardIcon/><br/>
                                <input disabled={locked?true:false} onChange={handleQuantityToAdd} type="text" value={quantityToAdd} style={{ width: "100px", height: "50px", borderRadius: "5px", textAlign: "center"}}/>
                                <span style={{width: "100%"}}><br/>Produit(s) sur palette : <DisplayAndAttribQuantityToPalette selectedPalette={selectedPalette} data_items={data} selectedPrepItems={selectedPrepItems}/></span>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <p><b>Préparé</b></p>
                                <div style={selectedPrepItems.quantity === selectedPrepItems.quantityTotalPrepared?{...styles, color: "rgb(0, 151, 0)",border: "2px solid rgb(43, 175, 43)", backgroundColor: "rgb(204, 255, 204)"}:{...styles, backgroundColor: "#ecf0f1", border: "1px solid #bdc3c7", color: "black"}}>
                                    <span>{selectedPrepItems.quantityTotalPrepared}</span>
                                    <span style={{textTransform: "uppercase"}}>{selectedPrepItems.quantityPrepared}</span>
                                </div>
                                <ButtonGroup
                                style={{marginTop: "31px", height: "50px", marginLeft: "auto", marginRight: "auto", width: "100%", justifyContent: "center"}}
                                disableElevation
                                variant="contained"
                                >
                                    <Button disabled={locked?true:false} onClick={actionPlusOrMinus} actionbtn={'plus'}><AddIcon/></Button>
                                    <ButtonAddWithPackage locked={locked} actionPlusOrMinus={actionPlusOrMinus} id_product={selectedPrepItems.product['@id']}/>
                                    <Button disabled={locked?true:false} onClick={actionPlusOrMinus} actionbtn={'doubleplus'}><AddIcon/><AddIcon/></Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                            <p style={{overflow: "hidden"}}><b>Palette en cours</b> <span style={selectedPaletteWeight && transportMaxWeight? (selectedPaletteWeight > transportMaxWeight)? {backgroundColor: "red", color: "white", fontWeight: "bold", padding:" 5px", whiteSpace: "nowrap"} : {backgroundColor: "rgb(0, 151, 0)", color: "white", fontWeight: "bold", padding:" 5px", whiteSpace: "nowrap"} : {backgroundColor: "rgb(0, 151, 0)", color: "white", fontWeight: "bold", padding:" 5px", whiteSpace: "nowrap"}}>{selectedPaletteWeight? selectedPaletteWeight/1000 : <CircularProgress size="1rem"/>}kg / {transportMaxWeight !== null? transportMaxWeight/1000 : <CircularProgress size="1rem"/> }kg</span></p>
                            {loadingPaletteList? <CircularProgress/> : <>
                            <select value={selectedPalette} onChange={handleChangePalette} style={{width: "100%", padding: "10px"}}>
                                {palettesPrep.map((palette)=>{
                                    return <option key={palette['@id']} value={palette['@id']}>n°{palette.number} --- {palette.typeName} --- Poids : {palette.weight/1000}kg</option>
                                })}
                            </select>
                            
                            
                            <div style={{marginTop: "5px", display: "flex"}}>
                            <DeleteButtonWithConfirm handleReloadPrepItemSelected={handleReloadPrepItemSelected} setSelectedPalette={setSelectedPalette} data_prep={data_prep} data={data} toggleSnack={props.toggleSnack} handleReload={handleReloadPalette} id_palette={selectedPalette} listOfPalette={palettesPrep}/>
                            <ButtonPalettePrint id_palette={selectedPalette} palette={false} orderPreparation={data_prep}/>
                            <AddPalette handleReload={handleReloadPalette} prepID={id_prep} selectPaletteAfterAdd={selectPaletteAfterAdd} displayFromPrep={true}/>
                            <ButtonEditPalette handleReload={handleReloadPalette} prepID={id_prep} selectedPalette={selectedPalette}/>
                            </div>    
                            
                            </>}
                            <br/>
                            <div style={{textAlign: "left",marginTop: "18px"}}>
                                <Button disabled={locked?true:false} onClick={addToPalette} variant="contained" style={locked?{marginTop: "5px", backgroundColor: "#b2bec3", margin:"5px", color:"#2d3436", height: "50px"}:{marginTop: "5px", backgroundColor: "rgb(204, 255, 204)", margin:"5px", color:"rgb(0, 151, 0)", height: "50px"}}>Ajouter a la palette</Button>
                            </div>
                            
                </Grid>
            </Grid>
        </Box>
        <hr></hr>
        <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
                <b>Emplacements et quantités à préparer</b><br/>
                <div style={{width: "100%", overflow: "auto", display: "flex", gap: "15px"}}>
                    {
                    data.map((prepItem, index)=>{
                    return (prepItem.prepared.percent === 100 ? <><span className="squareSwitch" numberPrepItem={index} onClick={selectThisPrepItems} style={{...styles, marginLeft: "initial", marginRight: "initial", color: "rgb(0, 151, 0)", border: "2px solid rgb(43, 175, 43)", backgroundColor: "rgb(204, 255, 204)", cursor:"pointer"}}><span>{prepItem.spot.spot}</span><span>{prepItem.prepared.content}</span></span><br/><br/></>:<><span className="squareSwitch" numberPrepItem={index} onClick={selectThisPrepItems} style={{...styles, marginLeft: "initial", marginRight: "initial",color: "rgb(153, 0, 0)", border: "2px solid rgb(153, 0, 0)", backgroundColor: "rgb(255, 204, 204)", cursor:"pointer"}}><span>{prepItem.spot.spot}</span><span>{prepItem.prepared.content}</span></span><br/><br/></>)  
                    })
                    }
                </div>
            </Grid>
            <Grid item xs={12} md={3} style={{textAlign: "center"}}>
                <Button onClick={finishPrep} disabled={data_prep.prepared.percent === 100?false:true} variant="contained" style={{marginBottom: "10px", marginRight:"5px"}} color="success"><PlayCircleFilledIcon/>Terminer la préparation et passer au contrôle</Button>
            </Grid>
        </Grid>
        </>);
}
export default PreparationSystem;
