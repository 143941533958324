import { Fab, LinearProgress, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getOnePalette, getPaletteTypeList, updatePaletteField } from "../../Services/Palettes/Palettes";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { addNewPaletteToPrep } from "../../Services/Commandes/Commandes";
import CreateIcon from '@mui/icons-material/Create';

const ButtonEditPalette = (props) => {
    let prepID = props.prepID;
    let handleReload = props.handleReload;
    let selectedPalette = props.selectedPalette;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingPalette, setLoadingPalette] = useState(true);
    const [paletteList, setPaletteList] = useState(null);

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [length, setLength] = useState(null);
    const [weight, setWeight] = useState(null);
    const [paletteType, setPaletteType] = useState(null);


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    useEffect(() => {
        setLoading(true);
        getOnePalette(selectedPalette).then((data)=>{
            
            setWidth(data.width);
            setHeight(data.height);
            setLength(data.length);
            setWeight(data.weight);
            setPaletteType(data['type']['@id']);
            setLoading(false);
            
        });
    
    }, [open]);

    function handleEdit(){
      
        updatePaletteField(selectedPalette, width, length, height, weight, paletteType).then(result=>{
          handleReload();
          handleClose();
        });
    }

    function handleWidth(e){
        let value = e.target.value
        setWidth(value);
    }

    function handleHeight(e){
        let value = e.target.value
        setHeight(value);
    }

    function handleLength(e){
        let value = e.target.value
        setLength(value);
    }

    function handleWeight(e){
        let value = e.target.value
        setWeight(value*1000);
    }

    useEffect(() => {
      setLoadingPalette(true);
      getPaletteTypeList().then((data)=>{
          setPaletteList(data['hydra:member']);
          setLoadingPalette(false);
      });
  
    }, [open]);

    function selectTypeHandle(e){
      let value = e.target.value;
      setPaletteType(value);
    }

    return (<>
     
      <Tooltip title={"Editer cette palette"}>
          <Fab onClick={handleClickOpen} style={{boxShadow:"unset", marginRight: "7px"}} size={"small"} color="default" aria-label="Edit">
            <CreateIcon/>
          </Fab>
      </Tooltip>
    
    
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Édition de la palette</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {console.log()}
          </DialogContentText>
          {

            loading? <LinearProgress/>:
            <div style={{display:"flex", justifyContent: "center", flexDirection: "Column"}}>
                {

                  loadingPalette? <LinearProgress/>:

                  <select value={paletteType} onChange={selectTypeHandle} style={{width: "100%", height: "25px", fontSize: "15px", marginTop: "10px", marginBottom: "10px"}}>
                    <option disabled selected> -- Séléctionner une palette</option>
                    
                    {
                      paletteList.map((palette)=>{
                        return <option key={palette.name} value={palette['@id']}>{palette.name}</option>;
                      })
                    }
                    
                    
                  </select>

                }
                <TextField style={{marginBottom: "15px"}} onChange={handleLength} value={length} id="filled-basic" label="Longueur" variant="filled" />
                <TextField style={{marginBottom: "15px"}} onChange={handleWidth} value={width} id="filled-basic" label="Largeur" variant="filled" />
                <TextField style={{marginBottom: "15px"}} onChange={handleHeight} value={height} id="filled-basic" label="Hauteur" variant="filled" />
                <TextField style={{marginBottom: "15px"}} onChange={handleWeight} value={weight/1000} id="filled-basic" label="Poids (kg)" variant="filled" />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={handleEdit}>Sauvegarder</Button>
        </DialogActions>
    </Dialog>    

    </>);
}

export default ButtonEditPalette;